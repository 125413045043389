import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import NewsLoop from "../components/newsLoop"
import SEO from '../components/seo'
import { NewsListWrapper } from "../style/common/common"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const CategoryArchive = ({ data, location, pageContext }) => {
  const category = pageContext.category
  const NewsArchivePosts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <NewsLoop post={edge.node} />)
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
      <Layout
        location={location}
        // title={title}
    >
      <SEO
        title={`${category} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={category}
      />
      <NewsListWrapper>
        {NewsArchivePosts}
      </NewsListWrapper>
      </Layout>
  )
}

export const query = graphql`
  query($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
      limit: 2000
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            category
            thumb {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
export default CategoryArchive
